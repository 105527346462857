import service from "@/common/request.js";
// 滑块验证提交后台
export function TencentCode(data) {
  return service({
    url: '/website/v1/verifyTencentImageCode',
    method: 'POST',
    data
  })
}
// 商城列表
export function marketList(data) {
  return service({
    url: '/website/v1/getNftCommodityList',
    method: 'POST',
    data: {
      ...data,
      export: 0,
    }
  })
}

// 查询Series
export function getCategoryList() {
  return service({
    url: '/website/v1/getCategoryList?export=0',
    method: 'GET',
  })
}

// 发送手机验证码
export function getCode(data) {
  return service({
    url: '/website/v1/sms/getCode',
    method: 'POST',
    data
  })
}
// 登录
export function login(data) {
  return service({
    url: '/website/v1/sms/login',
    method: 'POST',
    data
  })
}
// 查看用户点赞列表
export function getSysLikeList() {
  return service({
    url: '/website/v1/getSysLikeList',
    method: 'GET',
  })
}
// 更新点赞信息
export function updateLike(data) {
  return service({
    url: '/website/v1/updateNftCommodityLike',
    method: 'POST',
    data
  })
}
// nft商品详细信息
export function marketDetails(nftCommodityId) {
  return service({
    url: `/website/v1/getNftCommodityById?nftCommodityId=${nftCommodityId}`,
    method: 'GET',
  })
}
// 获取订单列表
export function getOrderList(orderStatus, page, pageSize) {
  return service({
    url: `/website/v1/getOrderList?orderStatus=${orderStatus}&page=${page}&pageSize=${pageSize}`,
    method: 'GET',
  })
}
// 校验银行卡号并获取token
export function getStripeTokenId(data) {
  return service({
    url: '/website/v1/getStripeTokenId',
    method: 'POST',
    data
  })
}
// 创建NFT藏品订单
export function createNftCommodityOrder(data) {
  return service({
    url: '/website/v1/createNftCommodityOrder',
    method: 'POST',
    data
  })
}
// Stripe支付
export function stripePay(data) {
  return service({
    url: '/website/v1/stripePay',
    method: 'POST',
    data
  })
}
// 根据订单号查看NFT商品详情
export function getNftCommodityByOrderNo(orderNo) {
  return service({
    url: `/website/v1/getNftCommodityByOrderNo?orderNo=${orderNo}`,
    method: 'GET',
  })
}
// 查看藏品类别对应数据
export function getCategoryData(data) {
  return service({
    url: `/website/v1/getCategoryData?category=${data}`,
    method: 'GET',
  })
}